import React, { useState } from 'react';

import HubspotForm from 'react-hubspot-form';

import { isDesktop } from 'react-device-detect';
import { downloadPdfFile } from '@src/utils/dowloadPdfFile';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { LoadingSpinner } from '@commons/loading-spinner/LoadingSpinner';
import CiCdPipelineThankYou from './CiCdPipelineThankYou';
import { privatePolicyInfo } from '@commons/append-tooltip/payload';
import { appendTooltip } from '@commons/append-tooltip/appendTooltip';

import whitePaperPdf from './white-paper-CI-CDv2.pdf';

import * as styles from './cicd-pipeline-form.module.scss';

export function CiCdPipelineForm() {
  const [formSubmitOk, setFormSubmitOk] = useState(false);
  const url =
    'https://resources.codilime.com/hubfs/White%20papers%20and%20case%20studies/CodiLime%20White%20Paper%20-%20CICD%20in%20six%20weeks.pdf';
  const policyInfo = privatePolicyInfo.type3;
  const mainTitle = 'Get the ultimate guide to your CI/CD';
  return (
    <StandardSection
      id="ci-cd-pipeline-form"
      className={styles.container}
      classNames={{ outerWrapper: styles.outerWrapper, innerWrapper: styles.innerWrapper }}
    >
      {formSubmitOk ? (
        <CiCdPipelineThankYou />
      ) : (
        <>
          <h3 className={styles.title}>{mainTitle}</h3>
          <div className="old-hubspot-form">
            <HubspotForm
              region="na1"
              portalId="5206705"
              formId="093ee9c2-8f21-424b-b51b-e996d9fb375a"
              loading={<LoadingSpinner />}
              onReady={() => appendTooltip('#ci-cd-pipeline-form', policyInfo)}
              onSubmit={() => undefined}
              onFormSubmitted={() => {
                if (isDesktop) {
                  window.open(url, '_blank', 'noopener noreferrer');
                } else {
                  downloadPdfFile(whitePaperPdf);
                }
                setFormSubmitOk(true);
              }}
            />
          </div>
        </>
      )}
    </StandardSection>
  );
}
