import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import { Header } from '@commons/header/Header';

import { CiCdPipelineForm, LearnAbout } from '@pages-impl/ci-cd-pipeline';

import featuredImage from '@images/ci-cd-pipeline/ci-cd-featured-image.png';

import * as styles from './ci-cd-pipeline.module.scss';

export default function CICDPipeline(props) {
  return (
    <Layout layout="landing" {...props} contactFormType={false}>
      <SEO
        title="CI/CD setup in six weeks - get a free guide"
        description="Get a free guide on how to implement, set up and optimize CI/CD in your company in just six weeks. Learn about the technology stack and process."
        featuredImage={featuredImage}
      />
      <Header
        title="How to implement CI/CD in just six weeks"
        id="ci_cd_pipeline_page"
        headerFigures={false}
        logoContainerDark={true}
        className={styles.cicdHeader}
        logoContainerClassName={styles.logoContainerHeader}
        headerMainContentClassName={styles.headerMainContent}
        headerOuterWrapper={styles.outerWrapper}
        displayTopBanner={false}
      />

      <div className={styles.cicdBody}>
        <LearnAbout />
        <CiCdPipelineForm />
      </div>
    </Layout>
  );
}
