import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';

import { ListRender } from '@commons/list-render/ListRender';

import { youWillGetToKnow } from './payload';

import cicdImage from '@images/ci-cd-pipeline/ci-cd-pipeline-desktop-img.svg';
import cicdImageMobile from '@images/ci-cd-pipeline/ci-cd-pipeline-mobile-img.svg';

import * as styles from './you-will-get-to-know.module.scss';

export function LearnAbout() {
  return (
    <div className={styles.container}>
      <p className={styles.description}>
        Get this practical guide to a CI/CD setup and optimization based on ten years of experience of CodiLime’s best
        DevOps engineers.
      </p>
      <BrowserView>
        <p className={styles.listHeading}>You will learn about: </p>
        <ListRender data={youWillGetToKnow} withArrows lime />
        <div className={styles.imageContainer}>
          <img loading="lazy" src={cicdImage} alt="ci cd pipeline" className={styles.imageDesktop} />
        </div>
      </BrowserView>
      <MobileView>
        <div className={styles.imageContainer}>
          <img loading="lazy" src={cicdImageMobile} alt="ci cd pipeline" className={styles.imageMobile} />
        </div>
        <p className={styles.listHeading}>You will learn about: </p>
        <ListRender data={youWillGetToKnow} withArrows lime />
      </MobileView>
    </div>
  );
}
