import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';
import { paths } from '@src/constants/pathsEnum';

import { Button } from '@commons/button/Button';
import { ModalPortal } from '@commons/modal-portal/ModalPortal';
import ContactPopup from '@commons/contact-popup/ContactPopup';

import envelopeImg from '@images/ci-cd-pipeline/cicdEnvelope.svg';

import * as styles from './cicd-thank-you.module.scss';

const CiCdPipelineThankYou = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img loading="lazy" src={envelopeImg} alt="" />
      </div>
      <h3>Thank you for submitting the form!</h3>
      <p>
        Your free guide is already waiting for you. You can read it on the spot or download it for later.
        <br />
        <br />
        If you have any questions, let us know.
      </p>

      {!isMobile ? (
        <>
          <Button onClick={() => setShowModal(true)} label="Contact us" />
          <ModalPortal>
            <ContactPopup showModal={showModal} setShowModal={setShowModal} />
          </ModalPortal>
        </>
      ) : (
        <Button href={`${paths.CONTACT}#contact`} label="Contact us" />
      )}
    </div>
  );
};

export default CiCdPipelineThankYou;
